<template>
  <div class="page">
    <div class="title">消息记录</div>
    <div class="main">
      <div class="box">
        <div class="ops">
          <div class="select">
            <el-select @change="getmessage" default-first-option v-model="currentselect" placeholder="请选择">
              <el-option label="全部消息" value=""></el-option>
              <el-option label="系统消息" :value="0"></el-option>
              <el-option label="用户消息" :value="1"></el-option>
              <el-option label="RFQ订阅" :value="2"></el-option>
              <el-option label="活动" :value="3"></el-option>
              <el-option label="ae商品监控" :value="4"></el-option>
              <el-option label="shopee商品监控" :value="5"></el-option>

            </el-select>
          </div>
          <div class="pageinfo">
            <el-pagination layout="total,prev, pager, next" @current-change="pagechange" :current-page="pageinfo.pagenum"
                           :page-size="pageinfo.pagesize" :total="pageinfo.total"></el-pagination>
          </div>
        </div>
        <div class="datainfo">
          <el-table :data="messagelist" :header-cell-style="{background:'#e8e8e8'}" :row-style="{height:'80px'}" row-key="id" border style="width: 100%" height="100%">
            <el-table-column prop="type" label="类型" width="130" align="center">
              <template slot-scope="scope">
                {{ scope.row.type === 0 ? '系统消息' : scope.row.type === 1 ? '用户消息' : scope.row.type === 2 ? 'RFQ订阅' : scope.row.type === 3 ? '活动' : scope.row.type === 4 ? 'ae商品监控' :  scope.row.type === 5 ? 'shopee商品监控' : ''}}
              </template>
            </el-table-column>
            <el-table-column prop="content" label="内容" align="left"></el-table-column>
            <el-table-column prop="time" label="时间" width="170" align="center"></el-table-column>
            <el-table-column label="查看" width="120" align="center">
              <template slot-scope="scope">
                <a target="_blank" :href="scope.row.action" class="pcolor">立即查看</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentselect: undefined,
      messagelist: [],
      pageinfo: {
        pagenum: 1,
        pagesize: 10,
        total: 0
      },
    }
  },
  created() {
    //单独处理token
    let geturl = window.location.href
    if (geturl.indexOf('?token=')) {
      let t1 = geturl.split('?token=')[1];
      let org = geturl.substring(0, geturl.indexOf('?token'))
      if (t1) {
        sessionStorage.setItem('lsou_token', t1);
        window.history.replaceState('', '', org);
      }
    }
  },
  mounted() {
    this.getmessage();
  },
  methods: {
    getmessage() {
      this.axios.get('/message/getusermsg', {
        params: {
          type: this.currentselect === -1 ? null : this.currentselect,
          pagenum: this.pageinfo.pagenum,
          pagesize: this.pageinfo.pagesize,
        }
      }).then(res => {
        if (res.code === this.global.CODE.SUCCESS) {
          this.messagelist = res.data.data
          this.pageinfo.total = res.data.total
          this.pageinfo.pagenum = res.data.pagenum
        }
      })
    },
    pagechange(v) {
      this.pageinfo.pagenum = v;
      this.getmessage();
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #313131;
}

.main {
  min-width: 780px;
  background-color: #fff;
  border-radius: 12px;
  margin: 15px 0;
  padding: 28px;
}

.main .box {
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 #ddd;
  padding: 20px;
  color: #666;
  display: flex;
  flex-direction: column;
}

.box .ops {
  display: flex;
  justify-content: space-between;
}

.box .ops >>> .el-input__inner {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  width: 120px;
}

.box .ops >>> .el-input__icon {
  line-height: unset;
}

.box .datainfo {
  padding: 20px 0;
}

>>> .el-table__body-wrapper {
  height: calc(100% - 60px) !important;
  font-size: 14px;
}
</style>
